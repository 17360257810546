// トークチャンネル最大個数
export const TALK_ROOM_GROUP_MAX = 50;

// トークDM最大個数
export const TALK_ROOM_DM_MAX = 500;

// トークチャンネル+DM+アーカイブ最大個数
export const TALK_ROOM_TOTAL_MAX = 1500;

// トークアーカイブ最大個数
export const TALK_ROOM_ARCHIVE_MAX = TALK_ROOM_TOTAL_MAX - TALK_ROOM_GROUP_MAX - TALK_ROOM_DM_MAX;

// トーク入力最大文字数
export const TALK_INPUT_TEXT_MAX = 5000;

// トーク返信最大文字数
export const TALK_REPLY_TEXT_MAX = 270;
