export const isIOS = () => {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true;
  } else {
    return !!(navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform));
  }
};

export const isSafari = () => {
  // iOS の場合、ブラウザは必ず webkit ベースなので、 safari とみなす
  // ref: https://qiita.com/pink/items/a54b8cadbe39a06956a9
  if (isIOS()) {
    return true;
  }

  // その他の OS の場合
  // ref: https://www.whatismybrowser.com/guides/the-latest-user-agent/
  return (
    /Safari/.test(navigator.userAgent) &&
    // PC Chrome および Edge も Safari を含むが、 Chrome も併せ持つのでそれを除外判定
    !/Chrome/.test(navigator.userAgent) &&
    // SP Chrome および Edge も Safari を含むが、 CriOS も併せ持つのでそれを除外判定
    !/CriOS/.test(navigator.userAgent)
  );
};

export const isAndroid = () => {
  const ua = navigator.userAgent.toLowerCase().trim();
  return ua.includes("android");
};
