export const featureFirestoreCache = (): boolean => {
  // フィーチャートグル: Firestore Cache 有効/無効判定
  return useRuntimeConfig().public.ENABLE_FIRESTORE_CACHE === "true";
};

export const featureConversion = (): boolean => {
  // フィーチャートグル: サンクスページ
  return useRuntimeConfig().public.ENABLE_CONVERSION === "true";
};

export const featurePlanMemberCountSetting = (): boolean => {
  // フィーチャートグル: 講座設定画面で会員数設定を表示するかどうか
  return useRuntimeConfig().public.ENABLE_PLAN_MEMBER_COUNT_SETTING === "true";
};

export const featurePriceSetting = (): boolean => {
  // フィーチャートグル: ENABLE_PRICE_SETTING が true の場合、価格設定機能を有効にする
  return useRuntimeConfig().public.ENABLE_PRICE_SETTING === "true";
};

export const featureChangePrice = (): boolean => {
  // フィーチャートグル: 価格変更
  return useRuntimeConfig().public.ENABLE_CHANGE_PRICE === "true";
};

export const featureOneTimeOffer = (): boolean => {
  // フィーチャートグル: ワンタイムオファー
  return useRuntimeConfig().public.ENABLE_ONE_TIME_OFFER === "true";
};

export const featureAllPlanManage = (): boolean => {
  // フィーチャートグル: 全講座管理
  return useRuntimeConfig().public.ENABLE_ALL_PLAN_MANAGE === "true";
};

// ENABLE_OWNER_JOIN
export const featureOwnerJoin = (): boolean => {
  // フィーチャートグル: オーナーが講座に入会する
  return useRuntimeConfig().public.ENABLE_OWNER_JOIN === "true";
};
