import { defineStore } from "pinia";
import { type DASHBOARD_TAB_TYPE } from "~/consts/DashboardTabMenuConfig";
import { useLoginStore } from "~/stores/LoginStore";

export const useDashboardTabStore = defineStore("DashboardTabStore", () => {
  const loginStore = useLoginStore();
  const tab = ref<DASHBOARD_TAB_TYPE>();

  const getTabs = () => {
    const tabs: DASHBOARD_TAB_TYPE[] = [];
    if (loginStore.isOwner()) {
      tabs.push("managedPlans");
      if (featureOwnerJoin()) {
        // フィーチャートグル: オーナーが講座に入会する
        tabs.push("joinedPlans");
      }
    } else {
      tabs.push("joinedPlans");
    }
    tabs.push("allPlans");
    tabs.push("owners");
    return tabs;
  };

  const initTab = () => {
    const tabs = getTabs();
    tab.value = tabs[0];
  };

  const setTab = (tabType?: DASHBOARD_TAB_TYPE) => {
    tab.value = tabType ?? getTabs()[0];
  };

  return {
    tab,
    getTabs,
    initTab,
    setTab,
  };
});
