import { serverTimestamp } from "firebase/firestore";
import { TalkOgp } from "./TalkOgp";

// メッセージタイプ
export type MessageType = "text" | "date" | "pdf" | "image" | "loading";

export enum DeleteType {
  none = 0,
  delete = 1,
  systemDelete = 9,
}

// トークデータ
export class Talk {
  constructor(
    public talkId: string,
    public talkRoomId: string, // firestoreには無いデータ。検索結果としてのみ設定される
    public messageType: MessageType,
    public contents: string,
    public contentsUrl: string,
    public fileName: string,
    public thumbnailImage: string, // サムネイル画像 (旧 base64)
    public thumbnailImageUrl: string, // サムネイル画像 (署名付きURL)
    public userId: string,
    public deleteType: DeleteType,
    public postDateTime: Date | null,
    public updateDateTime: Date | null,
    public note: string | undefined,
    public replyTalkId: string | undefined,
    public goodCount: number,

    // firestoreには無いデータ
    public ogpLoading: boolean,
    public ogpList: TalkOgp[],
    public replyTalk: Talk | undefined,
    public date: string | undefined, // 日付表示用
  ) {}
}

// Firestore data converter
export const TalkConverter = {
  toFirestore: (item: any) => {
    return {
      TalkId: item.talkId,
      Type: item.messageType,
      Message: item.contents,
      contentsUrl: item.contentsUrl,
      fileName: item.fileName,
      thumbnailImage: item.thumbnailImage,
      thumbnailImageUrl: item.thumbnailImageUrl,
      UserId: item.userId,
      deleteType: item.deleteType,
      InsertDate: item.postDateTime ?? serverTimestamp(),
      UpdateDate: item.updateDateTime ?? serverTimestamp(),
      replyTalkId: item.replyTalkId,
      GoodCount: item.goodCount,
    };
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    return new Talk(
      String(data.TalkId),
      "",
      data.Type,
      data.Message,
      data.contentsUrl,
      data.fileName,
      data.thumbnailImage,
      data.thumbnailImageUrl,
      data.UserId,
      data.deleteType,
      data.InsertDate?.toDate(),
      data.UpdateDate?.toDate(),
      data.note || undefined,
      data.replyTalkId ? String(data.replyTalkId) : undefined,
      data.GoodCount,
      false,
      [],
      undefined,
      undefined,
    );
  },
};

export const newLoadingTalk = (talkId: string) =>
  new Talk(
    talkId,
    "",
    "loading",
    "...",
    "",
    "",
    "",
    "",
    "",
    0,
    new Date(0),
    null,
    undefined,
    undefined,
    0,
    false,
    [],
    undefined,
    undefined,
  );
