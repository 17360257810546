export const filterEmptyObject = (dictionary: Record<string, any>) => {
  return Object.entries(dictionary)
    .filter(([_key, value]) => value)
    .reduce((obj: Record<string, any>, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});
};

// queryをURLSearchParamsに変換する関数
export const queryToUrlSearchParams = (query: Record<string, any>) => {
  try {
    const params = new URLSearchParams();
    // queryオブジェクトの各キーと値をURLSearchParamsに追加
    Object.keys(query).forEach(key => {
      const value = query[key];

      if (Array.isArray(value)) {
        // 値が配列の場合、同じキーで複数の値を追加
        value.forEach(item => params.append(key, item));
      } else if (value !== undefined) {
        // 値がundefinedでなければ追加
        params.append(key, value);
      }
    });
    return params;
  } catch (e) {
    return undefined;
  }
};

// queryを文字列として返す
export const getLinkQueryStrings = () => {
  const searchParams = queryToUrlSearchParams(useRoute().query);
  return searchParams ? `?${searchParams.toString()}` : "";
};
