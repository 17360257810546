<!-- メインレイアウト -->

<script setup lang="ts">
  import { useUserStore } from "~/stores/UserStore";
  import { usePlanTabStore } from "~/stores/PlanTabStore";

  const props = defineProps<{
    // ヘッダーの高さを横向きで変更するかどうか
    enableLandscapeHeader?: boolean;
  }>();

  // ヘッダーの高さ
  const headerHeight = getHeaderHeightRef(props.enableLandscapeHeader);

  // メインメニュー内で利用するため、ユーザー情報を取得する
  const userStore = useUserStore();
  userStore.fetch(true);

  const planTabStore = usePlanTabStore();
  const showFooter = computed<boolean>(() => {
    if (useRoute().path.includes("/live/")) {
      return false;
    }
    if (useRoute().path.includes("/selection/")) {
      return false;
    }
    if (useRoute().path.includes("/plan/")) {
      return (
        planTabStore.getTab() !== "talk" &&
        planTabStore.getTab() !== "overview" &&
        planTabStore.getTab() !== "member_list" &&
        planTabStore.getTab() !== "content"
      );
    }
    return true;
  });
</script>

<template>
  <v-layout class="rounded rounded-md">
    <OrganismsMainHeader :header-height="headerHeight" />
    <v-main class="w-100 h-100" :style="`transition: none; --v-layout-top: ${headerHeight}px`">
      <slot></slot>
    </v-main>
    <MoleculesFooter v-if="showFooter" />
  </v-layout>
</template>

<style lang="scss" scoped></style>
