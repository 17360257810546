import { defineStore } from "pinia";
import { DistributionRepository } from "~/Repositories/DistributionRepository";
import { DistributionInfo } from "~/entities/DistributionInfo";
import type { IDistributionRepository } from "~/interfaces/IDistributionRepository";

export const useDistributionStore = defineStore("DistributionStore", () => {
  const repository: IDistributionRepository = new DistributionRepository();
  const distributionInfo = ref<DistributionInfo>(new DistributionInfo());

  const reset = () => {
    distributionInfo.value = new DistributionInfo();
  };

  const uploadContentFile = async (file: File) => {
    const res = await repository.uploadContentFile(distributionInfo.value, file);
    distributionInfo.value.contentFile.fileKey = res.fileKey;
  };

  const uploadThumbnailFile = async (file: File, trimming: boolean = true) => {
    const convertedFile = await convertContentImage(file, trimming);
    const res = await repository.uploadThumbnailFile(distributionInfo.value, convertedFile);
    distributionInfo.value.thumbnailFile.fileKey = res.fileKey;
  };

  const setMoviePlayTimeSec = (moviePlayTimeSec: number) => {
    distributionInfo.value.moviePlayTimeSec = moviePlayTimeSec;
  };

  const postDistribution = async (): Promise<string> => {
    const res = await repository.postDistribution(distributionInfo.value);
    return res.contentsId;
  };

  const putDistribution = async () => {
    await repository.putDistribution(distributionInfo.value);
  };

  const deleteDistribution = async () => {
    await repository.deleteDistribution(distributionInfo.value);
  };

  return {
    distributionInfo,
    reset,
    uploadContentFile,
    uploadThumbnailFile,
    setMoviePlayTimeSec,
    postDistribution,
    putDistribution,
    deleteDistribution,
  };
});
