import { defineStore } from "pinia";
import { useLoginStore } from "./LoginStore";
import type { IPushNotificationRepository } from "~/interfaces/IPushNotificationRepository";
import { PushNotificationRepository } from "~/Repositories/PushNotificationRepository";

export const usePushNotificationStore = defineStore("PushNotificationStore", () => {
  const repository: IPushNotificationRepository = new PushNotificationRepository();

  const loading = ref(false);
  const enablePwa = useRuntimeConfig().public.ENABLE_PWA === "true";

  // Notification機能がブラウザ機能として存在するか
  // iosではpwa化しないとNotificationが使えない
  const notificationSupported = () => {
    if (enablePwa) {
      return "Notification" in window;
    }
    return false;
  };

  // service workerが登録されていない場合は登録する
  const registerServiceWorker = async () => {
    return await repository.registerServiceWorker();
  };

  // service workerがactiveかどうか
  const isActiveServiceWorker = async () => {
    return await repository.isActiveServiceWorker();
  };

  const deleteDeviceToken = async () => {
    loading.value = true;
    try {
      await repository.deleteDeviceToken();
    } finally {
      loading.value = false;
    }
  };

  // デバイストークンを登録する
  // showRequestがtrueの場合、通知許可を求める
  const registerDeviceToken = async (showRequest: boolean) => {
    if (loading.value) return;
    loading.value = true;
    try {
      const token = await repository.getDeviceToken(showRequest);
      if (token) {
        const loginStore = useLoginStore();
        // デバイストークンが変更されていたら or 以前登録してから24時間経っていたら登録する
        if (loginStore.shouldSendDeviceToken(token)) {
          loginStore.setDeviceToken(token);
          await repository.registerDeviceToken(token);
        }
      }
    } finally {
      loading.value = false;
    }
  };

  const registerPushNotification = async (planId: string) => {
    loading.value = true;
    try {
      await repository.registerPushNotification(planId);
    } finally {
      loading.value = false;
    }
  };

  const unregisterPushNotification = async (planId: string) => {
    loading.value = true;
    try {
      await repository.unregisterPushNotification(planId);
    } finally {
      loading.value = false;
    }
  };

  return {
    loading,
    notificationSupported,
    registerServiceWorker,
    isActiveServiceWorker,
    deleteDeviceToken,
    registerDeviceToken,
    registerPushNotification,
    unregisterPushNotification,
  };
});
