import type { ILoginRepository } from "~/interfaces/ILoginRepository";
import { LoginInfo, type IRegistEmailNotAuthUser } from "~/entities/LoginInfo";
import { type IHttpResponse } from "~/interfaces/IHttpResponse";

export class LoginRepository implements ILoginRepository {
  async login(idToken: string): Promise<LoginInfo> {
    const body = {
      id_token: idToken,
    };
    return await PostRequest<LoginInfo>("/login", JSON.stringify(body), true, false, true);
  }

  async register(idToken: string): Promise<LoginInfo> {
    const body = {
      id_token: idToken,
    };
    return await PostRequest<LoginInfo>("/register", JSON.stringify(body), true, false, true);
  }

  async adminLogin(idToken: string): Promise<LoginInfo> {
    const body = {
      id_token: idToken,
    };
    return await PostRequest<LoginInfo>("/admin/login", JSON.stringify(body));
  }

  async refresh(refreshToken: string): Promise<LoginInfo> {
    const body = {
      refresh_token: refreshToken,
    };
    return await PostRequest<LoginInfo>("/refresh", JSON.stringify(body), false, true);
  }

  // /regist_email_not_auth_userで登録するメールアドレスをPOST。ユーザーにメール送信。APIでユーザーセッション識別用のauth_codeを返却するのでブラウザはauth_codeを保持
  // /validate_email_not_auth_userでメールで送信した認証コードとメールアドレス・auth_codeを送信して有効性チェック
  // 有効性チェック後Firebaseログインをフロント側で実行
  // Firebaseログインが完了したら/auth_emailを2と同じリクエストで呼び出してもらいユーザーのemailアドレスとして登録
  async registEmailNotAuthUser(email: string): Promise<IRegistEmailNotAuthUser> {
    const body = {
      email: email,
    };
    return await PostRequest<IRegistEmailNotAuthUser>("/regist_email_not_auth_user", JSON.stringify(body), false, true);
  }

  async validateEmailNotAuthUser(email: string, authCode: string, mailAuthKey: string): Promise<IHttpResponse> {
    const body = {
      email: email,
      auth_code: authCode,
      mail_auth_key: mailAuthKey,
    };
    return await PostRequest<IHttpResponse>("/validate_email_not_auth_user", JSON.stringify(body), false, false, true);
  }

  async authEmail(email: string, authCode: string, mailAuthKey: string): Promise<IHttpResponse> {
    const body = {
      email: email,
      auth_code: authCode,
      mail_auth_key: mailAuthKey,
    };
    return await PostRequest<IHttpResponse>("/auth_email", JSON.stringify(body), false, false, true);
  }

  // /regist_email_change_passwordでパスワード変更するメールアドレスをPOST。ユーザーにメール送信。APIでユーザーセッション識別用のauth_codeを返却するのでブラウザはauth_codeを保持
  // /change_passwordでメールで送信した認証コードとメールアドレス・auth_codeを送信してパスワード変更
  async registEmailChangePassword(email: string): Promise<IRegistEmailNotAuthUser> {
    const body = {
      email: email,
    };
    return await PostRequest<IRegistEmailNotAuthUser>(
      "/regist_email_change_password",
      JSON.stringify(body),
      false,
      true,
    );
  }

  async changePassword(
    email: string,
    authCode: string,
    mailAuthKey: string,
    newPassword: string,
  ): Promise<IHttpResponse> {
    const body = {
      email: email,
      auth_code: authCode,
      mail_auth_key: mailAuthKey,
      password: newPassword,
    };
    return await PostRequest<IHttpResponse>("/change_password", JSON.stringify(body), false, false, true);
  }
}
