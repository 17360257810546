import { defineStore } from "pinia";
import type { NotificationSettings } from "~/entities/NotificationSetting";
import type { INotificationSettingRepository } from "~/interfaces/INotificationSettingRepository";
import { NotificationSettingRepository } from "~/Repositories/NotificationSettingRepository";

export const useNotificationSettingStore = defineStore("NotificationSettingStore", () => {
  const repository: INotificationSettingRepository = new NotificationSettingRepository();
  const notificationSettings = ref<NotificationSettings>();
  const loading = ref(false);

  const fetchSettings = async (planId: string) => {
    loading.value = true;
    try {
      notificationSettings.value = await repository.getSettings(planId);
    } finally {
      loading.value = false;
    }
  };

  const putSettings = async (planId: string, settings: NotificationSettings) => {
    loading.value = true;
    try {
      await repository.putSettings(planId, settings);
    } finally {
      loading.value = false;
    }
  };

  return {
    notificationSettings,
    loading,
    fetchSettings,
    putSettings,
  };
});
